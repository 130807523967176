<script>
import Gen from '@helper/Gen';
export default {
  props:{
    name:String,
    value:String,
    attr:{default:()=>({type:"text"})},
    label:String,
    mandatory:Boolean,
    col:{default:false},
    keyFilter: String,
  },
  data(){
    return {
      input: this.value,
    }
  },
  computed:{
    page(){ return this.$root.page },
    fields(){ return this.$root.fields },
    attrBind(){
      var attr = Gen.clone(this.attr)
      attr.placeholder = attr.placeholder || this.fields[this.name]
      return Object.assign(this.page.validation(this.name),attr)
    },
    labelCol(){ return this.col ? 'tr col-sm-'+(this.labelcol || 2) : '' },
    Col(){ return this.col ? 'col-sm-'+(this.col || 10) : '' },
  },
  methods:{
    labelValue(){
      if(this.label) return this.label
      return this.fields[this.name]
    },
    keyFilterBind(){ return this.keyFilter?'return '+this.keyFilter+'(event)':'' },
  },
  watch:{
    input(v){ this.$emit("input", v) },
    value(v){ this.input = v },
  }
}
</script>

<template>
  <div class="form-group mb-0">
    <label :class="labelCol">{{labelValue()}} <span v-if="page.validation(name).required||mandatory" class="text-danger">*</span></label>
    <div :class="Col">
      <slot>
        <input v-model="input" :name="name" class="form-control" :onKeypress="keyFilterBind()" v-bind="attrBind" />
      </slot>
      <label :error="name" :label="labelValue()"></label>
      <slot name="help"></slot>
    </div>
  </div>
</template>

